import React, { useState } from "react";
import { Upload } from "antd";
import PropTypes from "prop-types";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";

export default function UploadForm({ field, Form, label, imgUrl, listField }) {
  const [loadingImg, setLoadingImg] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoadingImg(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setLoadingImg(false);
      });
    }
  };
  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <UploadOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  if (Form === undefined) return <></>;
  else
    return (
      <Form.Item
        field={listField}
        fieldKey={[listField?.fieldKey, field]}
        name={listField ? [listField?.name, field] : field}
        label={label}
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload
          name="imagemPrincipal"
          listType="picture-card"
          className="avatar-uploader self-center"
          showUploadList={false}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt={field} style={{ width: "100%" }} />
          ) : imgUrl ? (
            <img src={imgUrl} alt={imgUrl} style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item>
    );
}
UploadForm.propTypes = {
  field: PropTypes.string,
  listField: PropTypes.any,
  label: PropTypes.string,
  imgUrl: PropTypes.any,
  Form: PropTypes.node,
};
