import React from "react";
import { Space, Input } from "antd";
import PropTypes from "prop-types";
import { MinusCircleOutlined } from "@ant-design/icons";
import UploadForm from "./uploadForm";

export default function Plant({
  field,
  remove,
  Form,
  initialValue,
  plants,
  setPlants,
  plantUrls,
  setPlantUrls,
}) {
  if (Form === undefined) return <></>;
  else
    return (
      <Space
        key={field?.key}
        className="grid lg:grid-cols-4 grid-cols-1 mb-0"
        align="start"
      >
        <UploadForm
          listField={field}
          name={[field?.name, "planta"]}
          fieldKey={[field?.fieldKey, "planta"]}
          imgUrl={initialValue?.imgUrl}
          field="planta"
          Form={Form}
        />
        <Form.Item
          {...field}
          listField={field}
          initialValue={initialValue?.titulo}
          name={[field?.name, "titulo"]}
          fieldKey={[field?.fieldKey, "titulo"]}
          rules={[{ required: true, message: "Adicione um título" }]}
        >
          <Input placeholder="Titulo" />
        </Form.Item>
        <Form.Item
          {...field}
          initialValue={initialValue?.descricao}
          name={[field?.name, "descricao"]}
          fieldKey={[field?.fieldKey, "descricao"]}
          rules={[{ required: true, message: "Adicione uma descrição" }]}
        >
          <Input.TextArea autoSize placeholder="Descrição" />
        </Form.Item>

        <MinusCircleOutlined
          onClick={() => {
            remove(field?.name);
            setPlants(plants.filter((p) => p.name !== plants.length - 1));
            initialValue?.imgUrl &&
              setPlantUrls(
                plantUrls.filter((p) => p.url !== initialValue.imgUrl)
              );
          }}
        />
      </Space>
    );
}

Plant.propTypes = {
  field: PropTypes.any,
  plants: PropTypes.array,
  setPlants: PropTypes.func,
  plantUrls: PropTypes.array,
  setPlantUrls: PropTypes.func,
  initialValue: PropTypes.any,
  remove: PropTypes.any,
  Form: PropTypes.node,
};
